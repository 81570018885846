import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar13() {
    const images = [];
    for (let i = 1; i <= 7; i++) {
        images.push({
            original: `/gallery/webinar-13/${i}.jpg`,
            thumbnail: `/gallery/webinar-13/thumbnails/${i}.jpg`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 13 "Experience Sharing Webinar" (June 13, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar13;
