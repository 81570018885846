import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import ErrorPage from './pages/error';
import MeetTeam from './pages/meet-team';
import KickOffMeeting from './pages/news-and-meet/kick-off-meeting';
import TrainingProgram from './pages/training-program';
import Courses from './pages/courses';
import FAQ from './pages/faq';
import UPC from './pages/university-psychological-centers';
import Gallery from './pages/gallery';
import Partners from './pages/partners';
import Contact from './pages/contact';
import NewsAndMeeting from './pages/news-and-meeting';
import ScrollToTop from './components/scroll-top';
import GalleryKickOffAtUniversity from './pages/gallery/kick-off-at-university';
import EstonianExperienceMeeting from './pages/news-and-meet/estonian-experience-meeting';
import GalleryEstonianExperience from './pages/gallery/estonian-experience';
import DevelopingExperienceMeeting from './pages/news-and-meet/experience-of-developing';
import GalleryDevelopingExperience from './pages/gallery/developing-experience';
import GalleryWebinar3 from './pages/gallery/webinar-3';
import Webinar3 from './pages/news-and-meet/webinar-3';
import Webinar4 from './pages/news-and-meet/webinar-4';
import GalleryWebinar4 from './pages/gallery/webinar-4';
import GalleryWebinar5 from './pages/gallery/webinar-5';
import Webinar5 from './pages/news-and-meet/webinar-5';
import GalleryWebinar6 from './pages/gallery/webinar-6';
import Webinar6 from './pages/news-and-meet/webinar-6';
import GalleryWebinar7 from './pages/gallery/webinar-7';
import Webinar7 from './pages/news-and-meet/webinar-7';
import Webinar89 from './pages/news-and-meet/webinar-89';
import GalleryWebinar89 from './pages/gallery/webinar-89';
import GalleryWebinar10 from './pages/gallery/webinar-10';
import Webinar10 from './pages/news-and-meet/webinar-10';
import GalleryWebinar11 from './pages/gallery/webinar-11';
import Webinar11 from './pages/news-and-meet/webinar-11';
import Webinar12 from './pages/news-and-meet/webinar-12';
import GalleryWebinar12 from './pages/gallery/webinar-12';
import GalleryWebinar13 from './pages/gallery/webinar-13';
import Webinar13 from './pages/news-and-meet/webinar-13';
import GalleryWebinar14 from './pages/gallery/webinar-14';
import Webinar14 from './pages/news-and-meet/webinar-14';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/meet-the-team" element={<MeetTeam />} />
        <Route path="/news-and-meeting" element={<NewsAndMeeting />} />

        <Route path="/kick-off-meeting" element={<KickOffMeeting />} />
        <Route path="/webinar" element={<EstonianExperienceMeeting />} />
        <Route path="/webinar-2" element={<DevelopingExperienceMeeting />} />
        <Route path="/webinar-3" element={<Webinar3 />} />
        <Route path="/webinar-4" element={<Webinar4 />} />
        <Route path="/webinar-5" element={<Webinar5 />} />
        <Route path="/webinar-6" element={<Webinar6 />} />
        <Route path="/webinar-7" element={<Webinar7 />} />
        <Route path="/webinar-89" element={<Webinar89 />} />
        <Route path="/webinar-10" element={<Webinar10 />} />
        <Route path="/webinar-11" element={<Webinar11 />} />
        <Route path="/webinar-12" element={<Webinar12 />} />
        <Route path="/webinar-13" element={<Webinar13 />} />
        <Route path="/webinar-14" element={<Webinar14 />} />

        <Route path="/training-programs" element={<TrainingProgram />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/university-psychological-centers" element={<UPC />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/contacts" element={<Contact />} />
  
        <Route path="/gallery/kick-off-at-university" element={<GalleryKickOffAtUniversity />} />
        <Route path="/gallery/webinar" element={<GalleryEstonianExperience />} />
        <Route path="/gallery/webinar-2" element={<GalleryDevelopingExperience />} />
        <Route path="/gallery/webinar-3" element={<GalleryWebinar3 />} />
        <Route path="/gallery/webinar-4" element={<GalleryWebinar4 />} />
        <Route path="/gallery/webinar-5" element={<GalleryWebinar5 />} />
        <Route path="/gallery/webinar-6" element={<GalleryWebinar6 />} />
        <Route path="/gallery/webinar-7" element={<GalleryWebinar7 />} />
        <Route path="/gallery/webinar-89" element={<GalleryWebinar89 />} />
        <Route path="/gallery/webinar-10" element={<GalleryWebinar10 />} />
        <Route path="/gallery/webinar-11" element={<GalleryWebinar11 />} />
        <Route path="/gallery/webinar-12" element={<GalleryWebinar12 />} />
        <Route path="/gallery/webinar-13" element={<GalleryWebinar13 />} />
        <Route path="/gallery/webinar-14" element={<GalleryWebinar14 />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
