import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar12() {
    const images = [];
    for (let i = 1; i <= 5; i++) {
        images.push({
            original: `/gallery/webinar-12/${i}.jpg`,
            thumbnail: `/gallery/webinar-12/thumbnails/${i}.jpg`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 12 "BURN Project Steering Committee Convenes" (June 12, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar12;
