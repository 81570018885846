import { Link } from 'react-router-dom';
import Content from '../components/content';

function Gallery() {

    return (
        <div>
            <Content
                title='Gallery'
                content={
                    <div className="grid grid-cols-3 gap-3 items-center">
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/kick-off-at-university"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/gallery/kick-off-at-university/1.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Kick-off at University of Tartu<br/> (12-14 February, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/gallery/estonian-experience/5.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 1 "Estonian experience in syllabus development"<br/> (April 22, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-2"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/gallery/developing-experience/1.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 2 "Experience of developing syllabuses by Latvian Universities"<br/> (April 29, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-3"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-3/2.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 3 "Germany's Syllabus Development Experience"<br/> (May 7, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-4"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-4/1.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 4 "Center for Psychological Resilience and Wellbeing" at University of Latvia<br/>(May 8, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-5"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-5/b2.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar  5 "Counselling Centre" at University of Tartu<br/>(May 13, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-6"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-6/3.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 6 "Course Evaluation" at International Psychoanalytic University Berlin GmbH<br />(May 14, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-7"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-7/1.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 7 "Working with Groups" at the University of Tartu<br />(May 20, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-89"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-89/4.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinars 8-9 "Psychological Support Center" at the University of Latvia and "Experience in teaching humanitarian courses" at the University of Tartu<br />(May 22-23, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-10"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-10/6.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 10 "Course Design and Evaluation" at Ternopil Volodymyr Hnatiuk National Pedagogical University (May 27, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-11"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-11/1.png"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 11 "Freie Universität Berlin's Psychological Counseling Service" (May 29, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-12"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-12/1.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 12 "BURN Project Steering Committee Convenes" (June 12, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-13"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-13/11.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 13 "Experience Sharing Webinar" (June 13, 2024)</div>
                            </Link>
                        </div>
                        <div className="w-80 h-auto border-2 rounded-lg items-center justify-center border-primary p-2 m-2 overflow-hidden">
                            <Link to={"/gallery/webinar-14"}>
                                <img
                                    className="object-cover h-auto max-w-full rounded-lg"
                                    src="/images/webinar-14/1.jpg"
                                    alt=""
                                />
                                <div className="text-center mt-2">Webinar 14 "Study Visit on Psychological Resilience and Training in Latvia" (September 23-27, 2024)</div>
                            </Link>
                        </div>
                    </div>
                } />
        </div>
    );
}

export default Gallery;
